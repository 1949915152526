<template>

<app-content :loading="is.loading">

	<app-content-head :title="parent.name" :tabs="tabs" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no players found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Badge" />
				<app-table-column text="Type" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="references.types[item.type]" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			references: {
				types: []
			},
			layout: 'auto 200px 24px',
			gatekeep: 'convention/schedule/event',
			endpoint: 'convention/schedule/event/:event/seats',
			live: 'convention/schedule/event/:event/seats'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Events.Edit', text: 'Setup', params: { id: this.parent.id } },
				{ name: 'Convention.Schedule.Event.Seats', text: (this.parent.ticket) ? 'Tickets' : 'Seats' },
				{ name: 'Convention.Schedule.Event.Messages', text: 'Messages' }
			]

		}

	}

}

</script>

<style scoped>

</style>